<template>
  <div class="row">
    <div class="col-xl-11" v-show="statData.length">
      <div class="row">
        <div v-for="stat of statData" :key="stat.icon" class="col-md-2">
          <Stat :data="stat || []" />
        </div>
      </div>
    </div>
    <div class="col-xl-1">
      <button type="button" class="btn btn-dark mr-1 bottomright" @click="saveCampaign()"><i class="bx bx-bar-chart-alt-2"></i> Exportar</button>
    </div>
  </div>
</template>
<script>
import Stat from '@/components/widgets/statCampaign'
import 'vue-loading-overlay/dist/vue-loading.css'
import http from '@/http'

export default {
  components: { Stat },
  props: {
    campaignId: {
      type: Number || String,
      require: true
    }
  },
  data () {
    return {
      timeout: 30000,
      statData: []
    }
  },
  methods: {
    getSubscribers () {
      http.get(`/admin/metrics/campaign/${this.campaignId}`).then((response) => {
        this.statData = response.data
      }).catch((error) => {
        console.error(error)
      })
    },
    cancelAutoUpdate () {
      clearInterval(this.timeout)
    },
    setValue (value) {
      console.log(value)
      this.getSubscribers()
    }
  },
  created () {
    // this.$parent.$on('update', this.setValue)
  },
  beforeDestroy () {
    this.cancelAutoUpdate()
  },
  mounted () {
    this.getSubscribers()
    this.timeout = setInterval(() => {
      this.getSubscribers()
    }, 30000)
    // this.connectSocket()
  }
}
</script>
<style>
.col-md-2-5 { width: 18.99% !important; margin: 5px }
.col-md-2-5:first { margin-left: 15px }
.bottomright {
  float: right;
  margin-top: 3rem;
}
</style>
