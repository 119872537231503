<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-6">
                <div class="search-box mr-2 mb-2 d-inline-block ">
                  <div class="position-relative">
                    <input type="text" class="form-control" v-model="search" placeholder="Digite e pressione 'ENTER'" @keyup.enter="doSearch"/>
                    <i class="bx bx-search-alt search-icon"></i>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="float-sm-end d-flex">
                  <div class="text-sm-right" v-if="user.role === 'Admin'">
                    <button type="button" class="btn btn-primary btn-rounded mb-2 mr-2" @click="openTemplates()">
                      Templates
                    </button>
                  </div>
                  <div class="text-sm-right" v-if="user.role === 'Admin'">
                    <button type="button" class="btn btn-success btn-rounded mb-2 mr-2" @click="newCampaign()">
                      <i class="mdi mdi-plus mr-1"></i> Nova Campanha
                    </button>
                  </div>
                </div>
              </div>
              <!-- end col-->
            </div>
            <div class="table-responsive">
              <table class="table table-centered table-nowrap">
                <thead>
                  <tr>
                    <th width="25%">Campanha</th>
                    <th width="15%">Lista</th>
                    <th width="15%">Progresso</th>
                    <th width="20%">Agendamento</th>
                    <th width="15%">Status</th>
                    <th>#</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="list in campaigns" :key="list.id">
                    <td class="link" @click="editCampaign(list.id)">{{ list.name }}</td>
                    <td class="link" @click="editCampaign(list.id)">{{ list.type_contact }}</td>
                    <td class="link" @click="editCampaign(list.id)">
                      <b-progress max="100" height="2rem" variant="success" v-if="list.progress > 0">
                        <b-progress-bar :value="list.progress">
                          <span>Progresso: <strong>{{ list.progress + '%'}}</strong></span>
                        </b-progress-bar>
                      </b-progress>
                      <span v-if="list.progress === 0" class="badge font-size-11 p-1 badge-secondary" style="min-width: 100%;margin-top:2px;background-color: #d9d9d9 !important">
                          0%
                      </span>
                    </td>
                    <td class="link" @click="editCampaign(list.id)">{{ list.scheduled_at ? list.scheduled_at : 'Sem agendamento'}}</td>
                    <td>
                      <span
                        :class="list.classStatus"
                        class="badge font-size-12 p-2" style="min-width: 120px">
                          {{ list.statusLabel }}
                      </span>
                    </td>
                    <td style="display: flex; gap: 4px;">
                      <button v-if="user.role === 'Admin'" type="button" @click="detailCampaign(list.id)" class="link btn btn-outline-primary btn-sm"><i class="bx bx-bar-chart-alt-2"></i></button>
                      <button v-if="user.role === 'Admin' && (list.status === 'pending' || list.status === 'scheduled')" type="button" @click="deleteCampaign(list)" class="link btn btn-outline-danger btn-sm"><i class="bx bx-trash"></i></button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @input="updateList"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <modal-form v-model="modalForm" :idCampaign="idCampaign" ref="formCampaign" @created="updateList"></modal-form>
    <modal-template v-model="modalTemplate" ref="formTemplate"></modal-template>
  </Layout>
</template>
<script>
import Swal from 'sweetalert2'
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import ModalForm from './Form'
import ModalTemplate from '../templates/Form.vue'
import appConfig from '@/app.config'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    Layout,
    PageHeader,
    ModalForm,
    ModalTemplate
  },
  name: 'Campanhas',
  page: {
    title: 'Campanhas',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      loading: true,
      search: '',
      timeout: null,
      modalForm: false,
      modalTemplate: false,
      params: {},
      totalRows: 1,
      currentPage: 1,
      idCampaign: null,
      perPage: 20,
      submitted: false,
      title: 'Campanhas',
      items: [{
        text: 'Campanhas'
      },
      {
        text: 'Listagem',
        active: true
      }
      ]
    }
  },
  mounted () {
    this.updateList()
    this.totalRows = this.pagination.total
    this.currentPage = this.pagination.current_page
    this.loading = false
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('campaign', ['campaigns', 'pagination']),
    rows () {
      return this.pagination.total
    }
  },
  methods: {
    ...mapActions('campaign', {
      getAll: 'getAll',
      delete: 'deleteCampaign',
      changeStatus: 'changeStatus'
    }),
    openTemplates () {
      this.$refs.formTemplate.open('')
      this.modalTemplate = true
    },
    newCampaign () {
      this.$refs.formCampaign.open('')
      this.modalForm = true
    },
    detailCampaign (id) {
      if (this.user.role === 'Admin') {
        this.$router.push({
          name: 'wa-campaign-details',
          params: {
            id: id
          }
        })
      }
    },
    async toggleStatus (id) {
      try {
        await this.changeStatus(id)
        let message = 'Sucesso: Status atualizado com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.updateList()
      } catch (err) {
        console.log(err)
        errorMessage('Alerta: ' + err.response.data.message)
      }
    },
    deleteCampaign (campaign) {
      Swal.fire({
        title: 'Você tem certeza ?',
        html: `Será excluido a campanha: <b>${campaign.name}</b>`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, DESEJO EXCLUIR!'
      }).then(result => {
        if (result.value) {
          try {
            this.delete(campaign.id)
            successMessage('Sucesso: Campanha excluída com sucesso.', 2500)
            this.updateList()
          } catch (err) {
            errorMessage('Alerta: ' + err.response.data.message)
          }
        }
      })
    },
    getReport (filter) {
      let params = {
        search: this.search || null,
        limit: this.perPage,
        page: this.currentPage
      }
      this.params = params
      let loeader = this.$loading.show()
      this.getAll(this.params).then((res) => {
        // this.participants = res.data
        this.totalRows = this.pagination.total
        this.currentPage = this.pagination.current_page
        loeader.hide()
      }).catch(error => {
        console.log(error)
        loeader.hide()
      })
    },
    doSearch () {
      this.params = { ...this.params, search: this.search }
      this.getReport(this.params)
    },
    updateList () {
      this.getReport(this.params)
    }
  }
}
</script>
<style scoped>
.link { cursor: pointer; }
.swal2-popup {
  font-size: 11px !important;
}
.table-responsive { min-height: 300px;}
.float-sm-end { float: right }
.progress {
  background-color: #d9d9d9 !important;
}
</style>
