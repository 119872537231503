<template>
  <b-modal v-model="modal" :title="title" size="lg" :no-enforce-focus="true" :no-close-on-backdrop="true">
    <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
    </loading>
    <div class="row">
      <div class="col-lg-12">
        <form @submit.prevent="saveRole()" autocomplete="off">
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label for="name">Nome</label>
                <input
                  id="name"
                  v-model="role.name"
                  name="name"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.role.name.$error
                  }"
                />
                <div
                  v-if="submitted && !$v.role.name.required"
                  class="invalid-feedback"
                >
                  O campo nome é obrigatório.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label for="name">Permissões</label>
              </div>
            </div>
            <div class="col-sm-12">
              <div role="tablist">
                <b-card no-body class="mb-1 shadow-none" v-for="(perm, group) in permissions" :key="group">
                  <b-card-header header-tag="header" role="tab">
                    <h6 class="m-0">
                      <a
                        v-b-toggle="'accordion-' + group"
                        class="text-dark"
                        href="javascript: void(0);"
                      >{{ group }}</a>
                    </h6>
                  </b-card-header>
                  <b-collapse :id="'accordion-' + group" accordion="perm" role="tabpanel">
                    <b-card-body>
                      <div class="form-check form-switch mb-3" v-for="(p, i) in perm" :key="i">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          :id="p.id"
                          v-model="role.permissions"
                          :value="p.id"
                        />
                        <label class="form-check-label" :for="p.id"
                          >{{ p.description }}</label
                        >
                      </div>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="secondary" @click="close()">Cancelar</b-button>
      <button type="submit" class="btn btn-primary mr-1" @click="saveRole()">Salvar</button>
    </template>
  </b-modal>
</template>
<script>
// Import component
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'
import { mapActions, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import roleService from './services'

export default {
  components: {
    Loading
  },
  props: {
    iduser: {
      type: Number || String,
      default: null
    }
  },
  data () {
    return {
      title: 'Nova Função',
      loading: true,
      selected: '',
      fullPage: true,
      submitted: false,
      permissions: [],
      optionsRooms: [],
      role: {
        permissions: []
      },
      modal: false
    }
  },
  validations () {
    return {
      role: {
        name: { required }
      }
    }
  },
  methods: {
    ...mapActions('roles', {
      getRole: 'getRole',
      postData: 'save'
    }),
    loadItem (id) {
      if (id) {
        this.title = 'Editar Função'
        this.getRole(id).then((data) => {
          this.role = this.data
          this.role.permissions = this.data.permissions.map((item) => item.id)
        }).then(() => {
          this.loading = false
        })
      }
    },
    loadPermissions () {
      roleService.listPermissions().then((res) => {
        this.permissions = res.data
      })
    },
    open (id = null) {
      this.modal = true
      this.loading = true
      this.loadPermissions()
      if (id) {
        this.loadItem(id)
        return
      }
      this.loading = false
    },
    async saveRole () {
      this.submitted = true
      this.$v.role.$touch()
      if (this.$v.role.$invalid) {
        window.scroll(0, 0)
        return
      }
      this.loading = true
      if (typeof (this.selected) === 'object') {
        this.role.district = this.selected.value
      }
      await this.postData(this.role).then(res => {
        let message = 'Sucesso: Dados armazenados com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.close()
        this.$emit('created')
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        this.loading = false
      })
    },
    close () {
      this.modal = false
      this.permissions = []
      this.role = {
        permissions: []
      }
      this.$v.$reset()
    }
  },
  computed: {
    ...mapState('roles', { data: 'admin_role' })
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    }
  }
}
</script>
<style scoped>
.vld-overlay.is-full-page {
    z-index: 1051 !important;
}
</style>
